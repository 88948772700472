:root {
  --typo-primary: #252c2e;
  --typo-promo: #4e5d60;
  --typo-ghost: #b8bbb7;
  --typo-action: #007f39;
  --bg-basic: #f3f3f3;
  --bg-secondary: #f7f7f7;
  --typo-source: #409f6b;
  --typo-radical: #bf3b4b;
  --dropdown-border: #ebebeb;
  --line-secondary: #f2f1f1;
  --typo-secondary: #818c99;
}
