.Wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Info {
  display: flex;
  margin-bottom: 10px;
}

.Info p {
  margin-right: 5px;
  font-weight: bold;
}
