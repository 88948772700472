@import '~normalize.css/normalize.css';
@import 'variables.css';

/* stylelint-disable */
html,
body,
#root {
  width: 100%;
  height: 100%;
}
/* stylelint-enable */
