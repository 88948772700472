.Header {
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background-color: #409f6b;
  position: relative;
  padding: 15px 24px;
}

.Title {
  display: inline-block;
  font-size: 18px;
  color: white;
  margin-bottom: 10px;
}

.Version {
  color: white;
  font-size: 12px;
  font-weight: 500;
}
